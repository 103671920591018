import { Component, OnInit } from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {KassenbuchService} from '../kassenbuch.service';
import {KassenbuchBelegInterface} from '../kassenbuch.interface';
import {DatePipe} from '@angular/common';

@Component({
  selector: 'app-send-money-popup',
  templateUrl: './send-money-popup.component.html',
  styleUrls: ['./send-money-popup.component.css']
})
export class SendMoneyPopupComponent implements OnInit {
  form: FormGroup;
  beleg: KassenbuchBelegInterface = null;
  paymentOptions = [];
  constructor(private ngbActiveModal: NgbActiveModal, private kassenbuchService: KassenbuchService, private datePipe: DatePipe) {
    const today = this.datePipe.transform(new Date(), 'y-MM-dd');
    this.form = new FormGroup({
      datum: new FormControl(today, [Validators.required]),
      done: new FormControl('0'),
      method: new FormControl(0)
    });
    this.form.controls.method.valueChanges.subscribe((change) => {
      this.form.patchValue({done: this.kassenbuchService.getDefaultSetDoneOptionForPaymentOption(+change)});
    });
  }

  ngOnInit() {
    if (this.beleg) {
      this.form.patchValue({method: this.kassenbuchService.getDefaultPaymentOptionForBelegArt(this.beleg.art)});
      this.paymentOptions = this.kassenbuchService.getPaymentOptions(this.beleg.art);
    }
  }

  cancel() {
    this.ngbActiveModal.dismiss();
  }

  confirm() {
    this.ngbActiveModal.close({
      datum: this.form.controls.datum.value,
      done: this.form.controls.done.value,
      method: this.form.controls.method.value
    });
  }
}
