import {Injectable} from '@angular/core';
import {IAuthUser} from '../models/auth-user.interface';
import {Store} from '@ngrx/store';
import {Observable} from 'rxjs';
import {MODULES} from '../routing/routes.model';
import {ITeamMemberState} from '../store/team-member-state.interface';
import {TeamMemberService} from './team-member.service';
import {ITeamMember} from '../cases/team-member.interface';
import {ICaseStatus} from '../models/case-status.interface';
import {Subscription} from 'rxjs/internal/Subscription';

@Injectable()
export class RightsManagementService {

  authUserObs: Observable<{authUser: IAuthUser}>;
  authUser: IAuthUser;
  caseStatusSub: Subscription;
  caseStatus: ICaseStatus[] = [];


  constructor(private store: Store<{authUser: {authUser: IAuthUser}, caseStatus: ICaseStatus[]}>,
              private teamMemberService: TeamMemberService) {
    this.authUserObs = this.store.select('authUser');
    this.authUserObs.subscribe(
      (authUser) => {this.authUser = authUser.authUser; }
    );
    this.caseStatusSub = this.store.select('caseStatus').subscribe(
      (caseStatus) => { this.caseStatus = caseStatus; }
    );
  }

  isNoUser() {
    if (this.authUser && this.authUser.user && this.authUser.user.userrole) { return false; }
    return true;
  }
  isDeveloper() {
    if (this.isNoUser()) { return false; }
    return (this.authUser.user.abbreviation === 'NL'); // Nick Landerer only :-)
  }

  isAdmin() {
    if (this.isNoUser()) { return false; }
    return (+this.authUser.user.userrole.level === 5);
  }

  isManagement() {
    if (this.isNoUser()) { return false; }
    return (+this.authUser.user.userrole.level === 4);
  }

  isRechercheLeader() {
    if (this.isNoUser()) { return false; }
    return (+this.authUser.user.userrole.level === 3);
  }

  isSeniorRechercher() {
    if (this.isNoUser()) { return false; }
    return (this.authUser.user.userrole.description === 'Senior-Ermittler');
  }

  isRechercher() {
    if (this.isNoUser()) { return false; }
    return (+this.authUser.user.userrole.level === 2);
  }

  isArchiveWorker() {
    if (this.isNoUser()) { return false; }
    return (+this.authUser.user.userrole.level === 1);
  }

  isNVZWorker() {
    if (this.isNoUser()) { return false; }
    return (+this.authUser.user.userrole.level === 0);
  }

  isRechtsanwalt() {
    if (this.isNoUser()) { return false; }
    return (+this.authUser.user.userrole.level === -1);
  }

  // new menu structure rights to use main menu entries
  isAllowedToUseMainMenuEntry(menu_entry) {
    if (menu_entry === 'ermittlung') {
      return this.isAllowedToUseModule(MODULES.CASES || this.isAllowedToUseModule(MODULES.PERSONS));
    }
  }

  // rights to use modules
  isAllowedToUseModule(module) {
    switch (module) {

      case MODULES.LOGIN:
        return true;

      case MODULES.DASHBOARD:
        return this.isAdmin() ||
          this.isManagement() ||
          this.isRechercheLeader() ||
          this.isRechercher() ||
          this.isArchiveWorker() ||
          this.isRechtsanwalt();

      case MODULES.CALENDAR:
        return this.isAdmin() ||
          this.isManagement() ||
          this.isRechercheLeader() ||
          this.isRechercher() ||
          this.isArchiveWorker();

      case MODULES.CASES:
        return this.isAdmin() ||
          this.isManagement() ||
          this.isRechercheLeader() ||
          this.isRechercher() ||
          this.isArchiveWorker() ||
          this.isRechtsanwalt();

      case MODULES.PERSONS:
        return this.isAdmin() ||
          this.isManagement() ||
          this.isRechercheLeader() ||
          this.isRechercher() ||
          this.isArchiveWorker();

      case MODULES.BUNDESANZEIGER:
        return (this.isAdmin() || this.authUser.user.is_allowed_to_use_module_bundesanzeiger);

      case MODULES.NAMENSVERZEICHNISSE:
        return this.isAdmin() ||
          this.isManagement() ||
          this.isRechercheLeader() ||
          this.isRechercher() ||
          this.isNVZWorker() ||
          this.authUser.user.is_allowed_to_edit_nvz ||
          this.authUser.user.is_allowed_to_use_nvz ||
          this.authUser.user.is_allowed_to_edit_all_nvz_entries;

      case MODULES.MAP:
        // return this.isDeveloper();
        return false;

      case MODULES.STATISTICS:
        return this.isDeveloper();

      case MODULES.ADDRESSBOOK:
        return this.isAdmin() ||
          this.isManagement() ||
          this.isRechercheLeader() ||
          this.authUser.user.is_allowed_to_see_addressbook;

      case MODULES.KASSENBUCH:
        return this.isAdmin() ||
          this.isManagement() ||
          this.isRechercheLeader();

      case MODULES.USERS:
        return this.isAdmin() ||
          this.isManagement() ||
          this.isRechercheLeader() ||
          this.isRechercher() ||
          this.isArchiveWorker();

      case MODULES.ADMINISTRATION:
        return this.isAdmin();

      case MODULES.GLOBALSEARCH:
        return this.isAdmin() ||
          this.isManagement() ||
          this.isRechercheLeader() ||
          this.isRechercher() ||
          this.authUser.user.is_allowed_to_see_all_cases;

      case MODULES.HELP:
        return this.isAdmin() ||
          this.isManagement() ||
          this.isRechercheLeader() ||
          this.isRechercher() ||
          this.isArchiveWorker();

      case MODULES.HISTORY:
        return this.isAdmin() ||
          this.isManagement() ||
          this.isRechercheLeader() ||
          this.isRechercher() ||
          this.isArchiveWorker();
    }
  }

  // other rights
  isAllowedToCreateNewStammbaum() {
    if (this.isAdmin() || this.isManagement() || this.isRechercheLeader()) { return true; }
    const teamMembers: ITeamMember[] = this.teamMemberService.teamMembersForCurrentCase;
    for (const mem of teamMembers) {
      if (mem.user_id === this.authUser.user.id && !mem.deleted && mem.leader) { return true; }
    }
    return false;
  }

  isAllowedToViewKassenbuchTabInCase() {
    if (this.isAdmin() || this.isManagement() || this.isRechercheLeader() || (!this.isNoUser() && this.authUser.user.is_allowed_to_use_kassenbuch_in_case_tab)) { return true; }
    return false;
  }

  isAllowedToViewAuskehrTabInCase() {
    if (this.isAdmin() || this.isManagement() || this.isRechercheLeader() || (!this.isNoUser() && this.authUser.user.is_allowed_to_use_auskehr_in_case_tab)) { return true; }
    return false;
  }

  isAllowedToSeeCaseList() {
    return (this.isAdmin() || (!this.isNoUser() && this.authUser.user.is_allowed_to_see_case_list));
  }

  isAllowedToSeeAuskehrList() {
    return this.isAdmin();
  }

  isAllowedToExchangeStammbaumPersonsAndMarriages() {
    if (this.isAdmin() || this.isManagement() || this.isRechercheLeader()) { return true; }
    const teamMembers: ITeamMember[] = this.teamMemberService.teamMembersForCurrentCase;
    for (const mem of teamMembers) {
      if (mem.user_id === this.authUser.user.id && !mem.deleted && (mem.leader || mem.tree_edit)) { return true; }
    }
    return false;
  }

  isAllowedToChangeStammbaumSettings() {
    if (this.isAdmin() || this.isManagement() || this.isRechercheLeader()) { return true; }
    const teamMembers: ITeamMember[] = this.teamMemberService.teamMembersForCurrentCase;
    for (const mem of teamMembers) {
      if (mem.user_id === this.authUser.user.id && !mem.deleted && (mem.leader || mem.tree_edit)) { return true; }
    }
    return false;
  }

  isAllowedToChangeStammbaumTreeStructureAndComments() {
    if (this.isAdmin() || this.isManagement() || this.isRechercheLeader()) { return true; }
    const teamMembers: ITeamMember[] = this.teamMemberService.teamMembersForCurrentCase;
    for (const mem of teamMembers) {
      if (mem.user_id === this.authUser.user.id && !mem.deleted && (mem.leader || mem.tree_edit)) { return true; }
    }
    return false;
  }

  isAllowedToEditStammbaumTreeContent() {
    if (this.isAdmin() || this.isManagement() || this.isRechercheLeader()) { return true; }
    const teamMembers: ITeamMember[] = this.teamMemberService.teamMembersForCurrentCase;
    for (const mem of teamMembers) {
      if (mem.user_id === this.authUser.user.id && !mem.deleted) { return true; }
    }
    return false;
  }

  isAllowedToSeeAllCases() {
    return (this.isAdmin() || (!this.isNoUser() && this.authUser.user.is_allowed_to_see_all_cases));
  }

  isAllowedToCreateNewCases() {
    return (this.isAdmin() || (!this.isNoUser() && this.authUser.user.is_allowed_to_create_new_cases));
  }

   isAllowedToDeleteTeamMembers() {
    return (this.isAdmin() || (!this.isNoUser() && this.authUser.user.is_allowed_to_remove_member_from_case)) ;
  }

   isAllowedToDeleteFiles() {
    return (this.isAdmin() || (!this.isNoUser() && this.authUser.user.is_allowed_to_delete_files));
  }

  isAllowedToSeeAllUserData() {
    return (this.isAdmin() || this.isManagement() || (!this.isNoUser() && this.authUser.user.is_allowed_to_see_all_user_data));
  }

  isAllowedToEditNVZ() { // is allowed to use the edit mode
    return (this.isAdmin() || this.isNVZWorker() || (!this.isNoUser() && this.authUser.user.is_allowed_to_edit_nvz));
  }

  isAllowedToEditAllNVZEntries() {
    // is allowed to use the edit mode and to edit not only own entries and entries especially permitted for this user, but all
    return (this.isAdmin() || (!this.isNoUser() && this.authUser.user.is_allowed_to_edit_all_nvz_entries));
  }

  isAllowedToSeePool() {
    if (this.isArchiveWorker()) { return false; }
    if (this.isRechtsanwalt()) { return false; }
    if (this.isAdmin() || this.isManagement() || this.isRechercheLeader() || this.isSeniorRechercher()) {
      return true;
    }
    return !this.isNoUser() && !this.authUser.user.is_excluded_from_pool
  }

  isAllowedToSearchCases() {
    return !this.isRechtsanwalt();
  }

  isAllowedToSeeJuniorPool() {
    return !this.isRechtsanwalt();
  }

  isAllowedToSeeBoerse() {
    return !this.isRechtsanwalt();
  }

  isAllowedToManageNVZEditRights() {
    return (this.isAdmin() || (!this.isNoUser() && this.authUser.user.is_allowed_to_manage_nvz_edit_rights));
  }

  isAllowedAutoparser() {
    return (
      (!this.isNoUser() &&
      this.authUser.user.abbreviation.toLowerCase() === 'cl' ||
      this.authUser.user.abbreviation.toLowerCase() === 'nl' ||
      this.authUser.user.abbreviation.toLowerCase() === 'bn' ||
      this.authUser.user.abbreviation.toLowerCase() === 'nle'));
  }

  isAllowedToDeleteEmptyPersons() {
    return (this.isAdmin() || this.isManagement() || this.isRechercheLeader());
  }

  isAllowedToAssignSenior() {
    return (
      this.isAdmin() ||
      this.isManagement() ||
      this.isRechercheLeader() ||
      (!this.isNoUser() && this.authUser.user.is_allowed_to_assign_senior));
  }

  isAllowedToUseDebugWidget() {
    return this.isAdmin();
  }

  isAllowedToDeleteNotes() {
    return this.isAdmin();
  }

  isAllowedToSeeEverythingOnDashboard() {
    return this.isAdmin();
  }

  isAllowedToSeeUserStructure() {
    return this.isAdmin() || this.isManagement() || this.isRechercheLeader() || this.isAllowedToAssignSenior();
  }

  getAllowedCaseStatusOptions(oldStatus: string) {
    const stat = this.caseStatus.find(x => +x.id === +oldStatus);
    const results = []
    const wfs = stat.workflow;
    for (const wf of wfs) {
      if (this.isAdmin() ||
        (!this.isNoUser() && (this.authUser.user.userrole.level >= wf.permission_level_required)) ||
        (!this.isNoUser() && this.authUser.user.is_allowed_to_do_all_status_changes)) {
          results.push({
            id: wf.id,
            to_status: wf.to_case_status,
            name: this.caseStatus.find(x => +x.id === +wf.to_case_status).description});
      }
    }
    return results
  }

  isAllowedToSendMassMail() {
    return this.isAdmin() || (!this.isNoUser() && this.authUser.user.is_allowed_to_send_mass_mails);
  }

  // ESA-Rights
  isAllowedToCreateESA() {
    return this.isAdmin() || (!this.isNoUser() && this.authUser.user.is_allowed_to_create_esa);
  }

  isAllowedToCreateEV() {
    return this.isAdmin() || this.isManagement() || this.isRechercheLeader();
  }

  isAllowedToUseEinsichtFAX() {
    return (!this.isNoUser() && this.authUser.user.signature_file && (  // todo remove part below (everybody is allowed to use it who has signature file)
      this.authUser.user.abbreviation.toLowerCase() === 'cl' ||
      this.authUser.user.abbreviation.toLowerCase() === 'nl' ||
      this.authUser.user.abbreviation.toLowerCase() === 'vg'
      ));
  }
}
